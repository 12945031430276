import * as React from "react";
import { PageLayout } from "../components/layout/layout";
import Seo from "../components/SEO/seo";
import Container from "../components/container/container";

//CSS
import * as StyledComponents from "../pageStyles/return.module.scss";

// markup
const ReturnPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Politique de Retour"
        lang="fr"
        description="Politique de Retour"
        meta=""
      />
      <Container>
        <h1 className={StyledComponents.title}>Politique de Retour</h1>
        <div className={StyledComponents.textContainer}>
          <p>
            CanadaOffroad.com veut que vous soyez entièrement satisfait de votre
            achat. Si vous avez des problèmes avec les produits que vous avez
            reçus, veuillez nous appeler au 1-800-xxx-xxxx pendant nos heures
            normales d'ouverture, ou envoyer un courriel à
            info@canadaoffroad.com et notre équipe d'experts travaillera avec
            vous pour trouver une solution.
          </p>
          <p>
            Si un produit est endommagé ou si vous recevez un produit incorrect
            (mauvaise finition, spécifications, etc.), nous vous demandons de
            prendre des photos du produit concerné et de nous contacter dès que
            possible. N'installez en aucun cas un produit endommagé ou
            incorrect.
          </p>
          <p>
            Si un défaut de fabrication est découvert lors de l'installation,
            nous vous demandons de prendre des photos du produit concerné et de
            nous contacter avant de poursuivre l'installation.
          </p>
          <p>
            Dans les rares cas de problème d'installation, Canada Offroad exige
            que les clients prennent des photos avant de demander un retour et
            ne couvrira pas les coûts de main-d'œuvre encourus.
          </p>
          <p>
            Dans les rares cas de retours dans lesquels il y a un échange /
            échange de produits, les deux options suivantes sont disponibles:
          </p>
          <p>
            &#x2737;Les retours ne seront pas acceptés sur les articles qui sont
            sans emballage d'origine
          </p>
          <p>
            <b>Méthode rapide : </b>
            Une deuxième commande sera traitée, facturée en totalité et expédiée
            à vous. Simultanément, un retour pour la commande originale sera
            traité. Un remboursement pour la deuxième commande sera émis une
            fois que la commande originale aura été retournée, reçue et
            inspectée.
          </p>
          <p>
            <b>Méthode longue : </b>Si vous ne souhaitez pas être facturé pour
            une deuxième commande, un retour pour votre commande originale sera
            traité. Une fois qu'il a été retourné, reçu et inspecté, une
            deuxième commande vous sera expédiée.
          </p>
          <p>
            Nous vous demandons de conserver toutes les boîtes d'origine et les
            emballages de protection pendant une semaine après la livraison
            complète de votre commande. Dans le cas d'un retour, nous demandons
            que les produits soient renvoyés dans le même état qu'ils ont été
            reçus à l'origine.
          </p>

          <p>
            <b>Autorisation nécessaire</b>
          </p>
          <p>
            Quelle que soit la situation, communiquez d'abord avec nous afin que
            nous trouvions la meilleure solution pour vous. Pour faciliter
            l'accès à votre dossier, ayez en main votre numéro de commande.
          </p>
          <p>
            Aucun retour non autorisé ne sera accepté, si vous refusez la
            commande à la réception, nous appliquerons la politique de retour
            selon les conditions applicables
          </p>
          <p>
            &#x2737;Canada Offroad accepte les retours dans les 30 jours suivant
            la livraison sous certaines conditions.
          </p>
          <p>
            <b>Conditions d'échange sans frais</b>
          </p>
          <p>&#x26AC; Le produit a été endommagé pendant le transport.</p>
          <p>&#x26AC; Le produit présente un défaut de fabrication.</p>
          <p>
            &#x26AC; Le produit ne correspond pas à ce qui a été acheté et
            décrit sur la facture.
          </p>
          <p>
            <b>Conditions pour un remboursement complet</b>
          </p>
          <p>
            &#x26AC; Une commande ne peut être exécutée et un produit comparable
            n'est pas disponible.
          </p>
          <p>
            &#x26AC; Un produit est reçu endommagé et un remplacement n'est pas
            disponible.
          </p>
          <p>
            &#x26AC; Une erreur de prix est commise au nom du fabricant et le
            surplus ne peut être absorbé ni par Canada Offroad ni par le
            consommateur.
          </p>
          <p>
            &#x26AC; Nous avons fait une erreur de prix sur le site Web et vous
            ne souhaitez pas payer le prix corrigé.
          </p>
          <p>
            <b>
              Condition pour un remboursement soumis à des frais de
              réapprovisionnement de 15% et frais d'expédition.
            </b>
          </p>
          <p>
            &#x26AC; Une commande est annulée par le client ou refusée à la
            livraison.
          </p>
          <p>
            &#x26AC; Vous avez changé d'avis après avoir reçu votre commande.
          </p>
          <p>
            &#x26AC; Vous avez sélectionné les mauvaises dimensions ou le
            mauvais produit.
          </p>
          <p>
            &#x26AC; Le produit que vous avez commandé est compatible avec votre
            véhicule, mais pas avec vos équipements existants ou les
            modifications qui auraient pu y être apportées (c'est-à-dire, des
            pneus qui ne sont pas compatibles avec les roues que vous possédez
            déjà ou l'inverse).
          </p>
          <p>
            &#x26AC; Vous avez commandé le mauvais type de pneus, de roues,
            d'accessoires ou vous avez saisi des informations incorrectes sur le
            véhicule au moment de placer la commande.
          </p>
          <p>
            &#x26AC; Vous avez changé d'avis et vous annulez votre commande
            après qu'elle aie été facturée ou traitée.
          </p>
          <p>
            <b>Conditions de non-retour</b>
          </p>
          <p>
            &#x26AC; Vous commandez des produits à l'encontre des
            recommandations de nos experts.
          </p>
          <p>&#x26AC; Produits en commande spéciale/vente finale.</p>
          <p>&#x26AC; Une commande ayant qu'un seul mag ou qu'un seul pneu.</p>
          <p>
            &#x26AC; Vous ou votre installateur avez endommagé le produit d'une
            quelconque façon.
          </p>
          <p>
            &#x26AC; Les pneus ont été montés sur des roues ou les roues ont été
            installées sur le véhicule.
          </p>
          <p>&#x26AC; Les pneus cloutables ont été cloutés.</p>
          <p>&#x26AC; Roues percées sur mesure.</p>
          <p>
            &#x26AC; Vous endommagez les produits en conduisant (Risques
            routiers).
          </p>
          <p>&#x26AC; 30 jours après la vente.</p>
          <p>&#x26AC; Roues qui ont été modifiées, peintes ou revêtues.</p>
          <p>&#x26AC; Roues ou pneus montés/équilibrés.</p>
          <p>
            &#x26AC; Les retours ne seront pas acceptés sur les articles qui
            sont sans emballage d'origine.
          </p>
          <p>
            &#x2737; Avant de monter les pneus, nous exigeons d'effectuer un
            essai d'ajustement pour nous assurer qu'il y a un jeu d'étrier et de
            suspension approprié.
          </p>
          <p>
            <b>Procédure de remboursement</b>
          </p>
          <p>
            Canada Offroad remboursera sur la carte de crédit utilisée pour
            l'achat initial.
          </p>
          <p>
            Veuillez noter que le remboursement peut apparaître dans votre
            compte de 5 à 10 jours ouvrables après sa demande.
          </p>
        </div>
      </Container>
    </PageLayout>
  );
};

export default ReturnPage;
